body{
  width: 100%;
  height: 100vh;
  background-color: #ebf2fc !important;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 15px;
  padding-left: 15px;
  border-radius: 24px;
  /* visibility: hidden; */
}
::-webkit-scrollbar-track {
  background: whitesmoke; 
  margin-left: 3%;
  border-radius: 24px;
  /* visibility: hidden; */
}
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.932); 
  border-radius: 24px;
  /* visibility: hidden; */
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  /* visibility: hidden; */
}
/* Skeleton Box Shimmer Animation */
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: ghostwhite !important; /* Background color of the skeleton box */
  border-radius: 5px; /* Adjust the border radius as needed */
  width: 100%; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
}

.skeleton-animation {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: shimmer 1s infinite linear;
}
@keyframes shimmer {
  100% {
    left: 100%;
  }
}



h1,h2,h3,h4,h5,h6,p,small,div{
  cursor:default
}
input, .request-card.active, .details-card{
  pointer-events: all;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.refreshBtn{
  /* position: absolute;
  left: -6%;
  
  top: 33%; */
  background-color: #03a8f43b;
  border-radius: 100px;
  padding: 5px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
 
}
.refreshBtn:hover > .material-symbols-outlined{
  transform: scale(1.45);
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.quoteDownload{
  position: absolute;
  top: 20%;
  right: 40%;
}
#requestCard,#detailsCard{
  overflow: hidden;
  backdrop-filter: blur(10px);
}
.custom-progress{
  height: 25px;
  border-radius: 105px;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mynavbar{
  background-color: white; 
  border-radius: 22px;
  width: 93%;
  margin-left: 5%;
  height: 100%;
  padding: 15px;
  z-index: 1000;
  position: relative;
  overflow: hidden;
}
.mynavbar.mobile{
  display: none;
}
.navlogo{
  position: absolute;
  width: 50%;
  bottom: 0;
  right: 0
}
.editButton{
  color: rgb(21, 101, 192);
  position: absolute;
  top: 3%;
  right: 2%;
  border-radius: 100%;
  padding: 5px;
  transition: 0.4s;
}
.editButton:hover{
  background-color: #1565c05e;
}
.navbar-icons{
  font-size: 1.6rem;
  color: #1565C0;
  width:50%;
  padding: 5px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.navbar-icons.active{
  background-color: #1565c05e;
}
.navbar-icons:hover{
  background-color: #1565c02c;
  cursor: pointer;
}

.custom-card{
  width: 93%;
  height: 100%;
  background-color: white; 
  border-radius: 22px;
  margin-left: 5%; 
  padding: 25px;
  overflow: hidden;
  overflow-y: auto;
}
.roundBtn{
  /* width: 25%;
  height: 28%; */
  width: 250px;
  height: 250px;
  background-color: #1565C0;
  color: white;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 25%;
  transition: 0.4s;
}
.roundBtn:hover{
  opacity: 0.8;
  cursor: pointer;
  transform: scale(1.2);
}
.roundBtn > .material-symbols-outlined{
  color: white;
  font-size: 50px;
}

.request-card{
  width: 15%;
  height: 30%;
  background-color: #1565C0;
  color: white;
  border-radius: 100%;
  margin-bottom: 3%;
  margin-right: 12%;
  transition: 0.4s;
}

.request-card.active{
  width: 75%;
  height: 85%;
  background-color: white; 
  border-radius: 25px;
  padding: 25px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.details-card{
  width: 75%;
  height: 60%;
  background-color: white; 
  border-radius: 25px;
  padding: 25px;
  margin: auto;
  margin-top: 1.5%;
  position: relative;
  /* overflow-x: hidden !important; */
}

.blue-card{
  width: 90%;
  padding: 25px;
  border-radius: 12px;
  background-color: #f5f9fd;
  margin: auto;
  color: #1a4280;
}
.continueBtn {
  width: 100%;
  background-color: #1976D2;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 100px;
  border: none;
}
.continueBtn:disabled {
  opacity: 0.2;
}
.continueBtn:disabled:hover {
  opacity: 0.2;
  cursor: not-allowed;
}
.continueBtn:hover {
  opacity: 0.8;
  cursor: pointer;
}
.custom-container{
  display: flex;
  background-color: white;
  width: 90%;
  padding: 10px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6
}
.searchRes{
  background-color: white;
  width: 100%;
  max-height: 225px;
  position:absolute;
  right: 0%;
  top:100%;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  z-index: 1000;
  padding: 15px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  text-align: center;
  overflow-y: auto;
}

.searchRes > p {
  padding: 5px;
  border-radius: 20px;
}

.searchRes > p:hover{
  cursor: pointer;
  background-color: #e0e0e067;
}
.searchRes > p:last-child {
  margin-bottom: 10%;
}
.dismiss-btn{
  position:absolute;
  right:2%;
  top:20%;
  transition: 0.2s;
  z-index: 1000;
}
.dismiss-btn:hover{
  cursor: pointer;
  transform: scale(1.2);
}
.react-datepicker-ignore-onclickoutside{
  height: 96% !important;
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--bs-body-color) !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: var(--bs-body-bg) !important;
  background-clip: padding-box !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}
.react-datepicker__input-container{
  height:100% !important
}

.react-datepicker__input-container > input {
  height:100% !important;
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--bs-body-color) !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: var(--bs-body-bg) !important;
  background-clip: padding-box !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.react-datepicker-popper{
  z-index: 9999 !important;
}

.approvalCircle {
  background-color: white;
  width: 125px;
  height: 125px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  margin: auto;
  margin-bottom: 10px;
  
  border: 10px solid #BDBDBD;
  color: #9E9E9E;

  transition: 0.6s;
}

.approvalCircle.pending{
  border: 10px solid #1976D2;
  color: #2196F3;
}

.approvalCircle.approved{
  border: 10px solid #4CAF50;
  color: #4CAF50;
}

.approvalCircle.denied{
  border: 10px solid #B71C1C;
  color: #B71C1C;
}

.connectLine{
  width: 0%;
  height: 20px;
  position: absolute;
  background-color: #1976D2;
  left: 70%;
  top: 30%;
  /* visibility: collapse; */
  transition: 0.6s ease-in-out;
}
.connectLine.active{
  width: 60%;
}
.connectLine.approved{
 background-color: #4CAF50;
}
.need-validation{
  border: 2.5px solid red;
}



 .need-validation ~ .input-group-text , .need-validation + .input-group-text {
  /* Your styles go here */
  border: 2.5px solid red !important;
}
.notifCard, .settingsCard{
  position: relative;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background-color: #f5f9fd;
  margin-bottom: 6%;
  transition: 0.4s;
}
.notifCard.complete
.notifCard > .material-symbols-outlined{
  font-size: 32px;
  color: #1E88E5;
  border-radius: 100%;
  padding: 5px;
  width:35px;
  position: absolute; 
  right: 5%;
  top: 10%;
}

.notifCard.approved > .material-symbols-outlined{
  color:#66BB6A;
}
.notifCard.denied > .material-symbols-outlined{
  color:#E53935;
}

.notifCard:hover {
  opacity: 0.8;
  cursor: pointer;
  transform: scale(1.05);
}

.links:first-child{
  margin-top: 15%;
}
.links{
  margin-bottom: 10%;
}
.links > p {
  color: black;
}
.links.active > p {
  color: #1565C0;
}
a{
  text-decoration: none !important;
}
.col-8{
  transition: 0.8s;
}
.statCard{
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 5%;
  transition: 0.4s;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.user-card{
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 5%;
  transition: 0.4s;

}
.card-inside{
  background-color: whitesmoke;
  padding: 10px;
  margin-bottom: 0.5%;
}
.statCardRound{
  width: 175px;
  height: 175px;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 100%;
  margin:auto;
  transition: 0.4s;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.requestCard{
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 5%;
  transition: 0.4s;
}

.historyCard{
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 20px;
  margin-right: 1%;
  transition: 0.4s;
  margin-bottom: 1%;
  width: 24%;
}
.requestCard:hover{
  opacity: 0.8;
  cursor: pointer;
  transform: scale(1.05);
}
.requestCardIcon{
  width: 20%;
  border-radius: 100%;
  position: absolute;
  object-fit: contain;
  right: -10%;
  top: 6%;
}
.sideCard{
  width: 100%;
  background-color: #1565c017;
  border-radius: 20px;
  padding: 10px;
  position: relative;
}
.sideCard:hover{
  cursor: pointer;
}
.searchCustom{
  border:none;
  width: 40%;
  border-radius: 100px;
  padding: 8px;
  /* box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1); */
  /* background-color: #e1e6f77a; */
  border: 2px solid #1565c0c7;;
}
.searchCustom:focus{
  border-color: rgb(209 196 233 / 15%) !important;
}

.search-icon{
  color: rgb(92, 107, 192);
  position: absolute;
  left: 30%;
  top: 0;
  background: #c4cce9;
  border-bottom-left-radius: 100px;
  padding: 8px;
  border-top-left-radius: 100px;
  border: 2px solid #1565c0c7;

}
.search-icon:hover{
  cursor:default
}

.filterButton{
  max-width: 200px;
  background-color: #1565c02d;
  border-radius: 12px;
  padding: 10px;
  margin-right: 2%;
  transition: 0.4s;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.filterButton:hover{
  opacity: 0.8;
  transform: scale(1.06);
  cursor: pointer;
}

.filterButton.active{
  background-color: #1565c0ad;
  color: white;
}

.selector{
  width: 8%;
  text-align: center;
  padding: 8px;
  border-radius: 100px;
  margin-right: 1%;
  transition:0.4s
}
.selector:hover{
  background-color: #1565c06a;
  color: white;
  cursor: pointer;
}
.selector.active{
  background-color: #1565C0;
  color: white;
}

.selector.other.active{
  background-color: #03A9F4;
  color: white;
}

.reallyBigNumber{
  font-size: 5rem;
  color:#1565C0
}

.input-group.need-validation{
  border: 1.5px solid red !important;
}
.getstartedBtn{
  position:absolute;
  margin-top:10%
}
.ellipsis{
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pill-container{
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 10px;
  overflow-y: auto;
  margin-bottom: 2%;
}

.pills{
  background-color: #1565c02d;
  border-radius: 12px;
  padding: 8px;
  margin-right: 2%;
  transition: 0.4s;
}
.pills.active{
  background-color: #1565c0b6;
  color: white;
}
.pills:hover{
  transform: scale(1.15);
}
.custom-card.login{
  margin:auto;
  height:70%;
  margin-top:10%;
  overflow: hidden;
}
.settings-col{
  height: 100%
}
.approverTitle{
  margin-top:2%;
  margin-left:1%;
  color:#1565C0
}
.lightContainer{
  border: 2px solid whitesmoke;
  border-radius: 12px
}
.budget_approved{
  position: absolute;
  right: 1%;
  top: 0;
}
.statsCard{
  position:relative;
  justify-content:center;
  align-items:center;
  background-color:whitesmoke;
  border-radius:12px;
  min-height: 300px;
}
@media (max-width: 1650px) {
  /* .roundBtn {
    width: 35%;
    height: 30%;

  } */
  .getstartedBtn{
    position:absolute;
    margin-top:30%
  }
}
@media (max-width: 1200px) {
  /* .roundBtn {
    width: 55%;
    height: 30%;

  } */
 
  .getstartedBtn{
    position:absolute;
    margin-top:50%
  }
  .request-card.active {
    width: 100%;
    height: 98%;
    margin-left: 2%;
   
  }
  .details-card{
    width: 100%;
    height: 95%;
  }
  .custom-card{
    margin-left: 0;
    width: 100%;
  }

}
@media (max-width: 991px) {
  .custom-col{
    height: 49.5%; 
    margin-bottom: 1%; 
  } 
  /* .roundBtn {
    width: 30%;
    height:25%;
    margin-top: 15%;
  } */
  .getstartedBtn{
    margin-top:30%
  }
  .custom-card{
    overflow-y: auto;
  }
}
@media (max-width: 767px) {
  .hide.advanced{
    display: none !important;
  }
  .mobilecontrol{
    display: none !important;  
  } 
  .custom-col{
    margin-left: 3.5%;
  }
  .mynavbar.mobile{
    display: flex;
    background-color: white;
    border-radius: 22px;
    width: 94%;
    /* margin-left: 5%; */
    height: 10%;
    padding: 15px;
    z-index: 1000;
    position: absolute;
    overflow: hidden;
    bottom: 15px;
    left: -2%;
    box-shadow: -1px -7px 15px 1px rgba(0,0,0,0.1);
  }
  .navlogo{
    width: 8%;
  }
  /* .roundBtn {
    width: 35%;
    height: 25%;
    margin-top: 5%;
  } */
  .navbar-icons {
    width: 75%;
  }
  .custom-card{
    width: 105%;
  }
  .request-card.active {
    margin-left: 4%;
    overflow-y: auto;
    padding: 10px;
  }
  .details-card{
    overflow-y: auto;
    
  }
}
@media (max-width: 565px) {
  .filterContainer{
    overflow-y: auto;
  }
  .searchCustom {
    width: 90%;
  }
  .search-icon {
    left: 5%;
  }
  .filterContainer > .filterButton:first-child{
    margin-left: 50%;
  }
  .blue-card{
    width: 100%;
  }
  .roundBtn {
    width: 175px;
    height: 175px;
  }
  .custom-card.contained{
    width: 100%;
    height: 70%;
    margin-left: 3%;
  }
  .details-card{
    height: 55%;
  }
  .approvalCircle {
    background-color: white;
    width: 90px;
    height: 90px;
  }
  .approvalCircle > span {
    font-size: 40px !important;
  }
  .connectLine {
    height: 5px;
    top: 20%;
    left: 80%;
  }
  .connectLine.active{
    width: 45%;
  }
  .custom-card.login{
    margin-top:40%
  }
}
@media (max-width: 431px) {
  .filterContainer > .filterButton:first-child {
    margin-left: 5%;
  }
  
  .statsCard{
    min-height: 175px;
  }
  .navbar-icons{
    font-size: 1.5rem;
  }
  .reallyBigNumber{
    font-size: 4rem;
    margin-top: 7%
  }
  .request-card.active {
    width: 100%;
    height: 86%;
    margin-left: 0;
    margin-top: 5%;
  }
  .details-card {
    height: 42%;
  }
  /* .roundBtn {
    width: 65%;
    height: 25%;
  } */
  .getstartedBtn {
    margin-top: 60%;
  }
  .hide{
    display: none !important;
  }
  .settings-col{
    height: 30% !important;
    margin-bottom: 3%;
  }
  .custom-card.contained {
    width: 100%;
    height: 59%;
    margin-left: 3%;
    margin-top: 2%;
  }
  .selector {
    width: 40%;
  }
  .historyCard{
    width: 100%;
    margin-bottom: 3%;
  }
  .custom-card.approver{
    margin-left: 3%;
    margin-top: 7%;
  }
  .approverTitle{
    margin-top: 10%;
    margin-left: 1%;
    color: rgb(21, 101, 192);
  }
}